@mixin hoverStyle {
  color: var(--button-hover-color);
  background-color: var(--button-hover-background-color);
  border-color: var(--button-hover-border-color);
  cursor: pointer;
}

.btn {
  appearance: none;
  border: none;
  background: transparent;
}

.btn {
  .innerBtn {
    border-radius: var(--border-radius-default);
    border-width: var(--border-width-default, 0px);
    border-style: var(--border-style, solid);

    position: relative;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

.btn:not(.nohover):hover {
  .innerBtn {
    @include hoverStyle();
  }
  &:disabled,
  &[aria-disabled="true"] {
    .innerBtn {
      color: var(--button-disabled-color) !important;
      border: var(--button-disabled-border) !important;
      background-color: var(--button-disabled-background-color) !important;
      cursor: not-allowed !important;
    }
  }
}

/*
Hvis vi kan unngå å bruke disabled så er det anbefalt pt ift UU. Heller gi feilmeldinger om hvorfor knappen ikke kan brukes ennå. 
*/
.btn:disabled,
/* Style for aria-disabled */
.btn[aria-disabled='true'] {
  .innerBtn {
    color: var(--button-disabled-color);
    border: var(--button-disabled-border);
    background-color: var(--button-disabled-background-color);
    cursor: not-allowed;
  }
}

/* Setter outline på selve button og ikke innerBtn for å unngå å få skygger etc på outline */
.btn:focus-visible {
  outline: var(--button-focus-visible-outline);
  outline-offset: var(--button-focus-visible-outline-offset);
}

/* Brukes for "Svar", "Neste" og lignende*/
.primary {
  transition: 0.025s ease-in;
  .innerBtn {
    color: var(--button-primary-color);
    background-color: var(--button-primary-background-color);
    // filter: var(--button-primary-filter);
    border: var(--button-primary-border);
    font-family: var(--button-primary-font-family);
    font-weight: var(--button-primary-font-weight);
    transition: 0.025s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Trigger when button is pressed down, affect child */
  &:active > div {
    transform: translate(0, 0);
    filter: none;
    &:disabled,
    &[aria-disabled="true"] {
      transform: translate(0, 0);
    }
  }
}
/* Brukes dersom det også skal være en sekundær action som f.eks "Tilbake", "Replay"*/
.secondary {
  .innerBtn {
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-background-color);
    border: var(--button-secondary-border);
    font-family: var(--button-secondary-font-family);
    font-weight: var(--button-secondary-font-weight);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Altenativ til secondary dersom man vil legge mindre vekt på knappen */
.ghost {
  .innerBtn {
    color: var(--button-ghost-color);
    background-color: var(--button-ghost-background-color);
    border-color: var(--button-ghost-border-color);
  }
}

/* Negative: Use for cancel / abort / retry */
.negative {
  color: var(--button-negative-color) !important;
  background-color: var(--button-negative-background-color) !important;

  &:hover {
    @include hoverStyle();
  }
}

/* Header: only use for buttons inside the Header */
.header {
  padding: var(--header-btn-padding);
  background-color: var(--header-btn-bg-color);
  border-radius: var(--header-btn-border-radius);
  margin-inline: var(--header-btn-margin-inline);
  margin-block: var(--header-btn-margin-block);
  z-index: 1;
  .innerBtn {
    padding-inline: var(--header-btn-inner-padding-inline) !important;
    width: var(--header-btn-inner-width) !important;
    height: var(--header-btn-inner-height);
    justify-content: center;
    svg {
      color: var(--header-svg-color);
    }
  }

  &:not(.nohover):hover {
    .innerBtn {
      border-radius: var(--header-btn-border-radius);
      background-color: var(--header-btn-inner-hover-color) !important;
      svg {
        color: var(--header-svg-hover-color);
      }
    }
  }
}

.headerClose {
  padding: var(--header-btn-padding);
  background-color: var(--header-btn-bg-color);
  border-radius: var(--header-btn-border-radius);
  margin-inline: var(--header-btn-margin-inline);
  margin-block: var(--header-btn-margin-block);
  z-index: 1;
  .innerBtn {
    padding-inline: var(--header-btn-inner-padding-inline) !important;
    width: var(--header-btn-inner-width) !important;
    height: var(--header-btn-inner-height);
    justify-content: center;
    svg {
      color: var(--header-svg-close-color);
    }
  }

  &:not(.nohover):hover {
    .innerBtn {
      border-radius: var(--header-btn-border-radius);
      background-color: var(--header-btn-inner-hover-color) !important;
      svg {
        color: var(--header-svg-hover-color);
      }
    }
  }
}

.accordion {
  width: 100%;
  padding-inline: 0;
  margin-bottom: 0.35rem;
  .innerBtn {
    color: var(--button-accordion-color);
    border-radius: var(--button-accordion-border-radius);
    justify-content: space-between;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    background-color: var(--button-accordion-background-color);
  }
  &:not(.nohover):hover {
    .innerBtn {
      background-color: var(
        --button-accordion-hover-background-color
      ) !important;
      color: var(--button-accordion-hover-color) !important;
    }
  }
}

/* Menu items */
.aside {
  padding: 0;
  color: var(--color-aside-txt);
  .innerBtn {
    width: 100% !important;
    svg {
      margin-right: 1rem;
    }
  }

  &:not(.nohover):hover {
    .innerBtn {
      border-radius: var(--aside-btn-border-radius);
      background-color: var(--color-aside-hover-background);
      color: var(--color-aside-hover-txt);
    }
  }
}

.link {
  .innerBtn {
    font-size: var(--font-size-small);
    background: cyan;
  }
}

.xsmall {
  .innerBtn {
    font-size: var(--button-xsmall-font-size);
    padding-block: var(--button-xsmall-padding-block);
    padding-inline: var(--button-xsmall-padding-inline);
    width: var(--button-xsmall-width);
    height: var(--button-xsmall-height);
  }
}
.small {
  .innerBtn {
    font-size: var(--button-xsmall-font-size);
    padding-block: var(--button-small-padding-block);
    padding-inline: var(--button-small-padding-inline);
    width: var(--button-small-width);
    height: var(--button-small-height);
  }
}
.medium {
  .innerBtn {
    font-size: var(--button-medium-font-size);
    padding-block: var(--button-medium-padding-block);
    padding-inline: var(--button-medium-padding-inline);
    width: var(--button-medium-width);
    height: var(--button-medium-height);
  }
}

.large {
  .innerBtn {
    font-size: var(--button-large-font-size);
    padding-block: var(--button-large-padding-block);
    padding-inline: var(--button-large-padding-inline);
    width: var(--button-large-width);
    height: var(--button-large-height);
  }
}

.full {
  .innerBtn {
    width: 100%;
    min-height: var(--button-medium-height); //3rem;

    @media screen and (max-width: 767px) {
      min-height: 3rem;
    }
  }
}

.long {
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 90%;
  }

  .innerBtn {
    min-height: var(--button-xsmall-height); //3rem;

    @media screen and (max-width: 767px) {
      min-height: 3rem;
    }
  }
}

.correct {
  .innerBtn {
    background-color: var(--color-correct, green);
  }
}
.incorrect {
  .innerBtn {
    background-color: var(--color-wrong, red);
  }
}
.open {
  .innerBtn {
    background-color: var(--accordion-open-background-color);
    color: var(--accordion-open-color);
  }
}
/*
* Display flex if button has multiple children
*/
.flex {
  .innerBtn {
    display: flex;
    align-items: center;

    /*     @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }

    @media screen and (orientation: landscape) {
      justify-content: flex-start;
    } */
  }
}

/*
* Text direction
*/
.rtl {
  .innerBtn {
    direction: rtl;
  }
}
