.modal {
  .modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-background-color);
  }
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--modalContent-background-color);
    width: var(--modalContent-width);
    height: auto;
    padding: var(--modalContent-padding);
    box-shadow: var(--modalContent-box-shadow);
    border-radius: var(--modalContent-border-radius);
    display: flex;
    flex-direction: column;
    &:focus-visible {
      outline: none;
    }

    .modalExitContainer {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      margin-bottom: 2rem;
      order: -1;

      .modalExitBtn {
        cursor: pointer;
        background-color: transparent;
        color: var(--modal-exit-icon-color);
        border: none;
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: var(--modal-exit-button);
        // svg {
        //   width: 1.5rem;
        //   height: 1.5rem;
        // }
      }
    }

    .modalTextContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-inline: 0.85rem;
      padding-top: 2rem;
      color: var(--modal-text-color);

      p.heading {
        font-weight: bold;
        font-size: var(--modal-heading-size);
      }
      p.text {
        font-size: var(--modal-text-size);
        margin-top: var(--modal-text-padding-top);
        line-height: var(--modal-text-line-height);
      }

      &:focus-visible {
        outline: none;
      }
    }

    .modalStatusNumbers {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-block: 2rem;
      font-weight: var(--font-weight-bold);
      color: var(--modal-text-color);

      .statusNumbers {
        font-size: 2.5rem;
      }
    }

    .modalBtnContainer {
      display: flex;
      justify-content: space-around;
      width: var(--modalBtnContainer-width);
      margin: var(--modalBtnContainer-margin);
      padding-bottom: 1rem;
    }

    .modalLinkTxt {
      color: var(--color-black);
      text-align: center;
      margin-block-end: 2rem;

      &:hover {
        color: var(--color-default-fill);
      }
    }
  }

  .modalBottomContainer {
    position: absolute;
    left: 50%;
    bottom: var(--modalBottomContainer-bottom);
    transform: translateX(-50%);
    button {
      min-width: var(--modalBottomContainer-button-min-width);
      font-size: var(--modalBtnContainer-button-font-size);
      font-weight: var(--modalBtnContainer-button-font-weight);
      padding: var(--modalBtnContainer-button-padding);
    }
  }

  .noFocusVisible {
    &:focus-visible {
      outline: none;
    }
  }
}
