// Define a reusable function for mobile landscape style
@mixin landscapemobile {
  @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    position: inherit;
    margin-block: 1rem;
  }
}

// Define container direction column center
@mixin columncenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin valueContainer {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
  span {
    font-weight: 700;
    font-size: 6.25rem;
  }

  span.valueSummary {
    font-size: 4rem;
  }
}

.container {
  @include columncenter();
  width: 100%;
  flex: 1;
  p {
    text-align: center;
    font-size: var(--container-text-size);
    margin-block: 1rem;
  }

  .ratingContainer {
    @include columncenter();
    justify-content: center;
    padding-top: 2rem;
    max-width: var(--ra-container-maxWidth);
    width: 100%;
    height: auto;

    &:focus-visible {
      outline: none;
    }

    .ratingQuestionText {
      font-size: var(--questionText-font-size);
      font-weight: var(--questionText-weight);
      text-align: center;
    }

    .valueTextContainer {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .valueContainer {
        @include valueContainer();
      }

      .ratingValues {
        display: flex;
        justify-content: center;
        .inputValues {
          margin-inline: 0.2rem;

          @media (pointer: fine) {
            &:hover {
              input + label {
                background-color: var(--ra-label-hover);
                color: var(--ra-label-hover-color);
              }
            }
          }

          input[type="checkbox"] {
            // Hide the default input radio button
            height: 0px;
            width: 0px;
            opacity: 0;
            -webkit-appearance: none;
            -moz-appearance: none;

            // Styles for checked input and label
            &:checked + label {
              background-color: var(--ra-checked-background-color, #263d51);
              color: var(--ra-checked-font-color);
              width: var(--ra-checked-width, 2.5rem);
              border: var(--ra-checked-border, none);
            }
          }
          
          label {
            display: inline-block;
            width: var(--ra-label-width, 2.25rem);
            text-align: center;
            font-family: var(--ra-label-font-family);
            background-color: var(--ra-label-background-color, #4e6689);
            color: var(--ra-label-color);
            font-weight: var(--ra-label-font-weight, 700);
            font-size: 1.85rem;
            padding: var(--ra-label-padding, 0.65rem);
            border: var(--ra-label-border);
            border-radius: var(--ra-label-border-radius, 8px);
            transition: var(--ra-label-transition, 0.025s ease-in);
            cursor: pointer;

            &:active {
              box-shadow: var(--ra-label-active-box-shadow, none);
              transform: translate(var(--ra-label-active-translate, 0 6px));
            }
          }

          input:focus-visible+label {
            outline: var(--outline,2px dashed blue);
            outline-offset: var(--outline-offset,2px)
          }
        }
      }
    }

    .instructionContainer {
      margin-block: 1rem;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    @media screen and (orientation: portrait) {
      justify-content: space-between;

      .valueTextContainer {
        height: 100%;

        .valueContainer {
          margin: -4rem 0 2rem 0;
        }
      }
    }
  }
}
