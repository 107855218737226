.errorMessageBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-block: 1rem;
  padding: 0.5rem;
  background-color: var(--color-wrong);
  border-radius: var(--border-radius-small);
  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.15);
  color: var(--color-black);

  .icon {
    background-color: var(--color-black);
    width: 30px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--color-true-white);
    }
  }

  .message {
    padding-left: 1.5rem;
    font-size: 1rem;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .errorMessageBox {
    padding-inline: 1rem;
  }
}
