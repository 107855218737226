// Define container direction column center
@mixin columncenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  padding-inline: 1rem;

  .textInputContainer {
    @include columncenter();
    width: 100%;
    padding-top: 2rem;
    flex: 1;
    justify-content: center;

    @media screen and (orientation: portrait) {
      justify-content: start;
    }

    .innerContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: var(--container-text-max-width);

      .inputText {
        font-weight: var(--container-text-weight);
        margin-bottom: 1rem;
        text-align: center;
      }

      form {
        @include columncenter();
        width: 100%;

        label {
          width: 100%;
          margin-bottom: 0.5rem;
        }

        .textareaWrapper {
          @include columncenter();
          position: relative;
          width: 100%;

          textarea {
            padding: 0.5rem;
            font-size: var(--textarea-font-size);
            box-shadow: var(--textarea-box-shadow);
            border-radius: var(--textarea-border-radius);
            background-color: var(--textarea-background-color);
            color: var(--textarea-color);
            width: var(--textarea-width);
            height: var(--textarea-height);
            resize: none;

            &::-webkit-scrollbar {
              width: var(--textarea-scrollbar-width);
            }
            &::-webkit-scrollbar-track {
              background: var(--textarea-scrollbar-track-bgcolor);
              border-radius: var(--textarea-scrollbar-track-border-radius);
            }
            &::-webkit-scrollbar-thumb {
              background: var(--textarea-scrollbar-thumb-background);
              border-radius: var(--textarea-scrollbar-thumb-border-radius);
            }
            scrollbar-color: var(--textarea-scrollbar-thumb-background)
              var(--textarea-scrollbar-track-bgcolor);
          }

          .characterCounter {
            pointer-events: none;
            padding-block: 0.25rem;
            color: inherit;
            width: 100%;

            &Error {
              @extend .characterCounter;
              color: red;
            }
          }
        }

        .instruction {
          width: 100%;
          margin-top: 2rem;
          margin-bottom: 1rem;
          text-align: center;
        }

        .buttonContainer {
          margin-block: 1rem;
        }
      }
    }
  }
}
