.notifications {
  background: var(--notifications-background);
  width: calc(100% - 2rem);
  height: calc(var(--font-size-small) * 2);
  margin-inline: 1rem;
  display: grid;
}

.notifications-background {
  background-color: var(--notifications-bg-line, white);
  width: 100%;
  height: 1px;
  grid-area: 1 / 1 / 2 / 2;
  margin-top: calc(var(--font-size-small) - 1px);
}

.notifications-background::before {
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background: var(--notifications-background-before);
}

.notifications-background::after {
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background: var(--color-true-white);
  background: var(--notifications-background-after);
}

.notifications-foreground {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notifications-content {
  font-family: var(--font-family-headings);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--notifications-content-color);
  border-radius: 9999px;
  padding-block: 0.1rem;
  padding-inline: 1.5rem;
  width: fit-content;
  overflow: hidden;
  background-color: var(--notifications-background-content);
  box-shadow: var(--notifications-content-box-shadow);
  min-width: var(--notification-content-width);
  text-align: center;
}

.counter {
  background-color: var(--color-box-fill, white);
  color: var(--color-txt);
  padding-inline: 1rem;
  text-align: center;
  margin-top: 3px;
}

.title {
  background-color: var(--color-btn-primary-fill);
  color: var(--color-true-white);
  padding-inline: 3rem;
}

.streak {
  background-color: var(--color-btn-primary-fill);
  color: var(--color-highlight-1);
  padding-inline: 2rem;
}

.hidden {
  visibility: hidden;
}
