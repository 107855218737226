.footer {
  color: var(--color-true-white);
  padding: var(--footer-padding-portrait);
  height: unset;
  flex-direction: column;
  align-items: center;

  p {
    font-family: var(--font-family-headings);
    text-align: center;
  }
  a {
    font-family: var(--font-family-headings);
    font-weight: 400;
    line-height: 1.875rem;
  }

  .containerLogo,
  .containerText,
  .containerLinks {
    margin-bottom: 1.5rem;
    min-width: 9rem;
  }

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    flex-direction: row;
    align-items: center;
    padding: var(--footer-padding-landscape);
    .containerLogo,
    .containerText,
    .containerLinks {
      margin-bottom: 0;
    }
  }
}
