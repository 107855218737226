.accordion {
  outline: var(--accordion-outline);
  background: var(--accordion-background);
}

.item {
  outline: var(--accordion-item-outline);
  background: var(--accordion-item-background);
}

.header {
  outline: var(--accordion-header-outline);
  background: var(--accordion-header-background);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .buttonTitle {
    width: 100%;
    margin-right: 0.5rem;
  }
}

.content {
  outline: var(--accordion-content-outline);
  background: var(--accordion-content-background);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  transition: 0.3s ease-in-out;
}

.open {
  height: auto;
  padding-block: 1rem;
  padding-inline: 1rem;

  p {
    margin-bottom: 1.25rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.close {
  height: 0;
  padding-block: 0;
  padding-inline: 1rem;
}
