.language_selector {
  display: flex;
  align-items: center;
  flex-direction: row;

  .language_icon {
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    padding-inline: 0px;
  }

  .textandicon {
    display: flex;
    align-items: center;
    color: var(--color-white);
    gap: 0.75rem;
    font-size: var(--font-size-small);

    &:hover svg {
      color: var(--color-default-fill);
    }

    &:hover a {
      color: var(--color-true-white);
    }
  }
}

.language_options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--color-white);
  font-size: var(--font-size-small);
  gap: 0.75rem;
  //margin-inline: 0.25rem;

  .language_option a {
    cursor: pointer;
    padding-inline: 0.25rem;

    &:hover {
      color: var(--color-default-fill);
    }
  }

  .selected {
    color: var(--color-true-white);
  }

  .selectedNoIcon {
    color: var(--color-default-fill);
  }
}

.isOpen {
  color: var(--color-default-fill);
}
