.headerPublic {
  background-color: var(--header-bg);
  padding: 1rem;
  height: unset;
  font-family: var(--font-family-headings);
  border-block-end: 2px solid var(--color-black);

  a {
    text-decoration: none;
    font-weight: normal;
  }

  .linkAndLanguage {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
.headerAuthenticated {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--header-bg);
  height: calc(var(--font-size-small) * 4.5);
  z-index: 10;

  .top-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: var(--header-bg-color);
    color: var(--header-text-color);
    height: calc(var(--font-size-small) * 4.5);
    justify-content: space-between;
    margin-block-end: calc(var(--font-size-small) * -1);
    padding-block: 0.5rem;
  }
}

.open {
  visibility: visible !important;
  transform: translateX(0) !important;
  z-index: 150 !important;
}
