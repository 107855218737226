.txt {
  line-height: 125%;
}
label .txt,
button .txt {
  line-height: 100%;
}

.xxsmall {
  font-size: var(--font-size-xxsmall);
}
.xsmall {
  font-size: var(--font-size-xsmall);
}
.small {
  font-size: var(--font-size-small);
}
.medium {
  font-size: var(--font-size-medium);
}
.large {
  font-size: var(--font-size-large);
}
.xlarge {
  font-size: var(--font-size-xlarge);
}
.xxlarge {
  font-size: var(--font-size-xxlarge);
}
.rtl {
  direction: rtl;
}
